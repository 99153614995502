import React from 'react';
import PropTypes from 'prop-types';

export default function EvergreenContent({ content }) {
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      className="BreederNewsEvergreenContent bg-near-white pv4 ph4 mh5 f2 br2"
    />
  );
}

EvergreenContent.propTypes = {
  content: PropTypes.string.isRequired
};
