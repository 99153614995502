import React from 'react';

import withGooglePlaces from 'dpl/decorators/withGooglePlaces';
import MobileLocationResultsList from 'dpl/search/components/MobileLocationResultsList';

function MobileLocationResultsListContainer(props) {
  return <MobileLocationResultsList {...props} />;
}

export default withGooglePlaces(MobileLocationResultsListContainer);
