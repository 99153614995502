import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { HOME_PATH } from 'dpl/shared/constants/urls';
import BreederMobileHeaderDropdownLoggedOutContent from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu/BreederMobileHeaderDropdownLoggedOutContent';
import BreederMobileHeaderDropdownLoggedInContent from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu/BreederMobileHeaderDropdownLoggedInContent';
import BuyerMobileHeaderDropdownLoggedInContent from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu/BuyerMobileHeaderDropdownLoggedInContent';
import GenericMobileHeaderDropdownContent from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu/GenericMobileHeaderDropdownContent';

export default class GlobalMobileMenuNavigationMenu extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    currentUser: PropTypes.shape({
      logout: PropTypes.func,
      value: PropTypes.shape({
        data: PropTypes.shape({
          buyer: PropTypes.bool,
          breeder: PropTypes.bool,
          profile_url: PropTypes.string,
          payments_enabled: PropTypes.bool
        })
      })
    }),
    onClose: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    notifications: PropTypes.object.isRequired
  };

  static defaultProps = {
    isLoggedIn: false,
    currentUser: {
      value: {
        data: {}
      }
    }
  };

  handleLogoutClick = () => {
    this.props.currentUser.logout().then(() => {
      window.location = HOME_PATH;
    });
  };

  render() {
    const {
      isLoggedIn,
      currentUser,
      notifications,
      currentUser: {
        value: { data: userData }
      },
      onClose
    } = this.props;

    return (
      <nav className="GlobalMobileMenuNavigationMenu ph5 pv5">
        <div>
          {isLoggedIn && userData.breeder && (
            <div
              className="bb b--light-gray pb4"
              data-test-id="GlobalMobileMenuNavigationMenu--breeder"
            >
              <BreederMobileHeaderDropdownLoggedInContent
                onLinkClick={onClose}
                onLogoutClick={this.handleLogoutClick}
                profilePath={currentUser.value.data.profile_url}
                paymentsEnabled={currentUser.value.data.payments_enabled}
                userData={userData}
                notifications={notifications}
              />
            </div>
          )}
          {isLoggedIn && userData.buyer && (
            <Fragment>
              <div
                className="bb b--light-gray pb4 mb6"
                data-test-id="GlobalMobileMenuNavigationMenu--buyer--loggedIn"
              >
                <BuyerMobileHeaderDropdownLoggedInContent
                  onLogoutClick={this.handleLogoutClick}
                  userData={userData}
                  notifications={notifications}
                />
              </div>
              <div
                className="bb b--light-gray pb4"
                data-test-id="GlobalMobileMenuNavigationMenu--breeder--loggedOut"
              >
                <BreederMobileHeaderDropdownLoggedOutContent />
              </div>
            </Fragment>
          )}
          {!isLoggedIn && (
            <Fragment>
              <BreederMobileHeaderDropdownLoggedOutContent />
            </Fragment>
          )}
        </div>
        <GenericMobileHeaderDropdownContent isLoggedIn={isLoggedIn} />
      </nav>
    );
  }
}
