/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React from 'react';
import PropTypes from 'prop-types';

import {
  BUYER_MESSAGES_PATH,
  BUYER_APPLICATIONS_PATH,
  BUYER_FAVORITES_PATH,
  BUYER_PAYMENTS_PATH,
  BUYER_SETTINGS_PATH,
  PUPPY_TRAINING_PROGRAM,
  PUPPY_TRAINING_PROGRAM_LANDING
} from 'dpl/shared/constants/urls';
import { hasPuppyCollegeAccess } from 'dpl/shared/buyer/utils';
import Icon from 'dpl/common/components/Icon';
import SmartLink from 'dpl/components/SmartLink';
import UserMobileHeaderDropdownTopSection from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu/UserMobileHeaderDropdownTopSection';
import NotificationCount from 'dpl/common/components/NotificationCount';

export default function BuyerMobileHeaderDropdownLoggedInContent({
  onLogoutClick,
  userData,
  notifications
}) {
  const puppyTrainingLink = hasPuppyCollegeAccess(userData?.entitlements)
    ? PUPPY_TRAINING_PROGRAM
    : PUPPY_TRAINING_PROGRAM_LANDING;

  return (
    <div className="BuyerMobileHeaderDropdownLoggedInContent">
      <UserMobileHeaderDropdownTopSection />
      <ul className="fw-medium">
        <li>
          <SmartLink
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            to={BUYER_MESSAGES_PATH}
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="message"
              />
            </span>
            <p>Messages</p>
            {notifications.unreadMessagesCount > 0 && (
              <NotificationCount
                className="ml3"
                count={notifications.unreadMessagesCount}
              />
            )}
          </SmartLink>
        </li>
        <li>
          <SmartLink
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            to={BUYER_APPLICATIONS_PATH}
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="application"
              />
            </span>
            <p>Applications</p>
          </SmartLink>
        </li>
        <li>
          <SmartLink
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            to={BUYER_FAVORITES_PATH}
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="favorite-heart-outline"
              />
            </span>
            <p>Favorites</p>
          </SmartLink>
        </li>
        <li>
          <SmartLink
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            to={BUYER_PAYMENTS_PATH}
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="money-payment"
              />
            </span>
            <p>Payments</p>
          </SmartLink>
        </li>
        <li>
          <SmartLink
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            to={BUYER_SETTINGS_PATH}
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="gear"
              />
            </span>
            <p>Account Settings</p>
          </SmartLink>
        </li>
        <li>
          <SmartLink
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            to={puppyTrainingLink}
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="dog"
              />
            </span>
            <p>Puppy Training</p>
          </SmartLink>
        </li>
        <li>
          <a
            className="BuyerMobileHeaderDropdownLoggedInContent__action flex"
            onClick={e => {
              e.preventDefault();
              onLogoutClick();
            }}
            role="button"
          >
            <span className="mr3">
              <Icon
                className="BuyerMobileHeaderDropdownLoggedInContent__icon"
                name="logout"
              />
            </span>
            <p className="flex-auto">Log out</p>
          </a>
        </li>
      </ul>
    </div>
  );
}

BuyerMobileHeaderDropdownLoggedInContent.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    unreadMessagesCount: PropTypes.number
  }).isRequired,
  userData: PropTypes.shape({
    first_name: PropTypes.string,
    name: PropTypes.string,
    profile_photo_url: PropTypes.string,
    entitlements: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};
