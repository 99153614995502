export const ITEM_TYPES = {
  EVENT: 'event',
  ARTICLE: 'article',
  PODCAST: 'podcast',
  WEBINAR: 'webinar',
  PRODUCT: 'product'
};

export const ITEM_TYPE_TO_COLOR = {
  [ITEM_TYPES.EVENT]: 'stone-700',
  [ITEM_TYPES.ARTICLE]: 'midnight',
  [ITEM_TYPES.PODCAST]: 'forest-700',
  [ITEM_TYPES.WEBINAR]: 'secondary',
  [ITEM_TYPES.PRODUCT]: 'magenta-dark'
};

export const ITEM_TYPE_TO_BG_COLOR = {
  [ITEM_TYPES.EVENT]: 'bg-pine-light',
  [ITEM_TYPES.ARTICLE]: 'bg-cloud',
  [ITEM_TYPES.PODCAST]: 'bg-stroll-light',
  [ITEM_TYPES.WEBINAR]: 'bg-passive',
  [ITEM_TYPES.PRODUCT]: 'bg-magenta-light'
};

export const ITEM_TYPE_TO_ICON = {
  [ITEM_TYPES.EVENT]: 'event',
  [ITEM_TYPES.ARTICLE]: 'fetch-article',
  [ITEM_TYPES.PODCAST]: 'podcast',
  [ITEM_TYPES.WEBINAR]: 'webcam',
  [ITEM_TYPES.PRODUCT]: 'fetch-application-alt'
};
