import React from 'react';
import ProTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

export default function GlobalMobileMenuCloseButton({ closeMenu }) {
  return (
    <button
      className="GlobalMobileMenuCloseButton no-select secondary pv2 fw-light"
      onClick={closeMenu}
      type="button"
    >
      <Icon name="fetch-close-lg" width="24px" height="24px" />
    </button>
  );
}

GlobalMobileMenuCloseButton.propTypes = {
  closeMenu: ProTypes.func.isRequired
};
