import {
  BREEDER_DASHBOARD_PATH,
  BREEDER_BASE_WAITLIST_PATH,
  BREEDER_PAST_FAMILIES_PATH,
  BREEDER_MESSAGES_PATH,
  BREEDER_LITTERS_PATH,
  BREEDER_SIRES_DAMS_PATH,
  BREEDER_PAYMENTS_PATH,
  BREEDER_INSIGHTS_PATH
} from 'dpl/shared/constants/urls';
import { breederAppPaymentSettingsShowQueryDefinition } from 'dpl/shared/breeder/payments/utils/queryDefinitions';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useNotificationCounts from 'dpl/shared/hooks/useNotificationCounts';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

// TODO: might be able to remove 'pathname' parameter when react-router is upgraded
export default function useDashboardRoutes(
  pathname = window.location.pathname
) {
  const currentUser = useCurrentUser();
  const { notifications } = useNotificationCounts();

  const {
    payments_enabled: paymentsEnabled,
    insights_enabled: insightsEnabled,
    approved: breederApproved,
    breeder_profile_id: breederProfileId
  } = currentUser.value.data;

  const paymentSettingsShowResource = useResourceQuery({
    ...breederAppPaymentSettingsShowQueryDefinition(breederProfileId),
    enabled: Boolean(breederProfileId)
  });

  const routes = [
    {
      icon: 'fetch-home',
      path: BREEDER_DASHBOARD_PATH,
      title: 'Overview',
      locked: false
    },
    insightsEnabled && {
      icon: 'fetch-insights',
      path: BREEDER_INSIGHTS_PATH,
      title: 'Insights'
    },
    {
      icon: 'fetch-message',
      path: BREEDER_MESSAGES_PATH,
      title: 'Inbox',
      notificationsCount: notifications.unreadMessagesCount,
      locked: !breederApproved
    },
    {
      icon: 'fetch-list',
      path: BREEDER_BASE_WAITLIST_PATH,
      title: 'Waitlist',
      locked: !breederApproved
    },
    {
      icon: 'fetch-family',
      path: BREEDER_PAST_FAMILIES_PATH,
      title: 'My Owners',
      locked: !breederApproved
    },
    {
      icon: 'fetch-litter',
      path: BREEDER_LITTERS_PATH,
      title: 'Listings',
      locked: false
    },
    {
      icon: 'fetch-dog',
      path: BREEDER_SIRES_DAMS_PATH,
      title: 'Sires & Dams',
      locked: false
    }
  ].filter(Boolean);

  let paymentsCount = notifications.outstandingBipsCount || 0;

  if (
    paymentSettingsShowResource.isSuccess &&
    !paymentSettingsShowResource.value.data.id
  ) {
    paymentsCount++;
  }

  if (paymentsEnabled) {
    routes.push({
      icon: 'fetch-payment',
      path: BREEDER_PAYMENTS_PATH,
      title: 'Payments',
      notificationsCount: paymentsCount,
      locked: !breederApproved
    });
  }

  return routes.map(r => ({ ...r, isActive: pathname.startsWith(r.path) }));
}
