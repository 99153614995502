import React from 'react';

import { STATUS } from 'dpl/breeder_preferred_qualifications/utils/constants';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { get } from 'dpl/shared/utils/object';
import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';

export default function UserMobileHeaderDropdownTopSection() {
  const currentUser = useCurrentUser();
  const user = get(currentUser, 'value.data');

  const isPreferredBreeder = user.breeder_preferred_status === STATUS.PREFERRED;

  return (
    <div className="mb1 flex justify-between">
      <div>
        <p className="flex-auto font-24 font-serif fw-medium">
          Hello, {user.first_name || user.name}!
        </p>
        {isPreferredBreeder && (
          <div className="flex items-center">
            <PreferredBreederBadge
              className=""
              width="16px"
              height="18px"
              hideTooltip
            />
            <p className="dark-gray ml1 f2">Preferred Breeder</p>
          </div>
        )}
      </div>
      <UserAvatar user={{ ...user, id: user.user_id }} size="sm" />
    </div>
  );
}
