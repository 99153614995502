import React from 'react';
import { compose } from 'redux';

import { isMobileUA } from 'dpl/shared/utils';
import withMobileMenu from 'dpl/decorators/withMobileMenu';
import withCurrentUser from 'dpl/decorators/withCurrentUser';
import withNotificationsCount from 'dpl/decorators/withNotificationsCount';
import GlobalMobileMenu from 'dpl/components/GlobalMobileMenu';

const IS_MOBILE_UA = isMobileUA();

function GlobalMobileMenuContainer(props) {
  // HACK: prevent any mobile menu rendering on bigger screen
  if (IS_MOBILE_UA) {
    return <GlobalMobileMenu {...props} />;
  }

  return null;
}

export default compose(
  withMobileMenu,
  withCurrentUser,
  withNotificationsCount
)(GlobalMobileMenuContainer);
