import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { MENU_TYPES } from 'dpl/decorators/withMobileMenu';
import MobileMenu from 'dpl/components/MobileMenu';
import GlobalMobileMenuSearchMenu from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuSearchMenu';
import GlobalMobileMenuNavigationMenu from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuNavigationMenu';
import BreederNewsLayoutContainer from 'dpl/breeder_news/containers/LayoutContainer';

export default class GlobalMobileMenu extends Component {
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      logout: PropTypes.func,
      value: PropTypes.shape({
        data: PropTypes.shape({
          buyer: PropTypes.bool,
          breeder_profile_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
          ]),
          breeder: PropTypes.bool,
          profile_url: PropTypes.string
        })
      })
    }).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    openMobileMenuName: PropTypes.string,
    selectedSearchType: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    notifications: PropTypes.object.isRequired,
    openSearchBreedersMenu: PropTypes.func.isRequired,
    openSearchSheltersMenu: PropTypes.func.isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    openMobileMenuName: null,
    selectedSearchType: null,
    onChange: null
  };

  static getDerivedStateFromProps(props, state) {
    const { openMobileMenuName } = props;

    return {
      // disable slide animation if opening or closing the menu
      disableTransition:
        (!state.openMobileMenuName && Boolean(openMobileMenuName)) ||
        !openMobileMenuName,
      openMobileMenuName
    };
  }

  state = {
    disableTransition: false,
    openMobileMenuName: null
  };

  componentDidUpdate(props) {
    const isOpenState = this.state.openMobileMenuName;
    const isOpenProp = props.openMobileMenuName;

    if (isOpenState !== isOpenProp) {
      props.onChange?.(isOpenState);
    }
  }

  render() {
    const {
      closeMenu,
      currentUser,
      isLoggedIn,
      selectedSearchType,
      notifications,
      openSearchBreedersMenu,
      openSearchSheltersMenu
    } = this.props;

    const { disableTransition, openMobileMenuName } = this.state;

    const isOpen = Boolean(openMobileMenuName);
    const searchIsShown = openMobileMenuName === MENU_TYPES.SEARCH;
    const navigationIsShown = openMobileMenuName === MENU_TYPES.NAVIGATION;
    const breederNewsIsShown = openMobileMenuName === MENU_TYPES.BREEDER_NEWS;

    return (
      <MobileMenu
        className={classnames('GlobalMobileMenu', {
          'GlobalMobileMenu--breederNews': breederNewsIsShown
        })}
        isOpen={isOpen}
        position="top"
        zIndex={isOpen ? '0' : '-1'}
      >
        <MobileMenu
          className="GlobalMobileMenu__subMenu"
          disableTransition={disableTransition}
          position="left"
          isOpen={searchIsShown}
        >
          <GlobalMobileMenuSearchMenu
            isOpen={searchIsShown}
            selectedSearchType={selectedSearchType}
            openSearchBreedersMenu={openSearchBreedersMenu}
            openSearchSheltersMenu={openSearchSheltersMenu}
          />
        </MobileMenu>
        <MobileMenu
          className="GlobalMobileMenu__subMenu"
          disableTransition={disableTransition}
          position="right"
          isOpen={navigationIsShown}
        >
          <GlobalMobileMenuNavigationMenu
            currentUser={currentUser}
            isLoggedIn={isLoggedIn}
            onClose={closeMenu}
            notifications={notifications}
          />
        </MobileMenu>
        {isLoggedIn && currentUser.value.data.breeder && (
          <MobileMenu
            className="GlobalMobileMenu__subMenu"
            disableTransition={disableTransition}
            position="left"
            isOpen={breederNewsIsShown}
          >
            <BreederNewsLayoutContainer />
          </MobileMenu>
        )}
      </MobileMenu>
    );
  }
}
