import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederAppEditBreederNewsReadResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import withCurrentUser from 'dpl/decorators/withCurrentUser';

import { newsItems } from '../utils/resourceDefinitions';
import Layout from '../components/Layout';

function LayoutContainer(props) {
  return <Layout {...props} />;
}

export default compose(
  withCurrentUser,
  __DEPRECATED_defineResources(
    (
      _,
      {
        currentUser: {
          value: {
            data: { breeder_profile_id: breederProfileId }
          }
        }
      }
    ) => ({
      newsItemsData: newsItems,
      breederProfileData: {
        ...breederAppEditBreederNewsReadResourceDefinition(breederProfileId),
        auto: false
      }
    })
  ),
  connect((_, { newsItemsData, breederProfileData }) => ({
    isLoading:
      !newsItemsData.meta.isSuccess || !breederProfileData.meta.isSuccess,
    newsItems: newsItemsData.value.data.items,
    evergreenContent: newsItemsData.value.data.evergreen_content,
    isFirstTime:
      !breederProfileData.value.breeder_profile.last_news_item_seen_at
  }))
)(LayoutContainer);
