import React from 'react';

import withMobileMenu from 'dpl/decorators/withMobileMenu';
import GlobalMobileMenuCloseButton from 'dpl/components/GlobalMobileMenu/GlobalMobileMenuCloseButton';

function GlobalMobileMenuCloseButtonContainer(props) {
  return <GlobalMobileMenuCloseButton {...props} />;
}

export default withMobileMenu(GlobalMobileMenuCloseButtonContainer);
