import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { POPULAR_AREAS } from 'dpl/shared/constants/shelters';
import {
  LocationResultsResultAreaItem,
  LocationResultsResultGoogleItem
} from 'dpl/search/components/LocationResultsList';
import SearchNoResults from 'dpl/components/Search/SearchNoResults';

export default function MobileLocationResultsList({
  googlePlacesResults,
  query,
  onSelectItem
}) {
  return (
    <div className="MobileLocationSearchResultsList">
      {(() => {
        if (!query) {
          return (
            <Fragment>
              <p className="tertiary font-14 fw-medium mb2">Popular areas</p>
              {POPULAR_AREAS.map(area => (
                <LocationResultsResultAreaItem
                  key={area.url}
                  area={area}
                  onClick={onSelectItem}
                />
              ))}
            </Fragment>
          );
        }

        return googlePlacesResults.length === 0 ? (
          <SearchNoResults query={query} />
        ) : (
          googlePlacesResults.map((place, idx) => (
            <LocationResultsResultGoogleItem
              key={idx}
              place={place}
              onClick={onSelectItem}
            />
          ))
        );
      })()}
    </div>
  );
}

MobileLocationResultsList.propTypes = {
  query: PropTypes.string,
  googlePlacesResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      getCoordinates: PropTypes.func.isRequired
    })
  ).isRequired,
  onSelectItem: PropTypes.func.isRequired
};

MobileLocationResultsList.defaultProps = {
  query: null
};
