import { coordinatesToParams } from 'dpl/shared/utils/url';

export function navigateToShelterResults(googlePlaceOrArea) {
  if (googlePlaceOrArea.url) {
    window.location = googlePlaceOrArea.url;
    return Promise.resolve();
  }

  return googlePlaceOrArea.getCoordinates().then(coord => {
    window.location = `/shelters#locate=${coordinatesToParams(coord)}`;
  });
}
