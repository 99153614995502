import React from 'react';

import {
  EXPLORE_SHELTERS_PATH,
  LEARNING_CENTER_PATH,
  STANDARDS_PATH
} from 'dpl/shared/constants/urls';
import SmartImage from 'dpl/components/SmartImage';

import menuSheltersJpg from 'dpl/assets/menu_shelters.jpg';
import menuLearningCenterJpg from 'dpl/assets/menu_learning_center.jpg';
import menuStandardsJpg from 'dpl/assets/menu_standards.jpg';

const USER_MENU_LINKS = [
  {
    path: EXPLORE_SHELTERS_PATH,
    title: 'Find shelters & rescues',
    image: menuSheltersJpg,
    shouldHide: true
  },
  {
    path: LEARNING_CENTER_PATH,
    title: 'Visit the learning center',
    image: menuLearningCenterJpg
  },
  {
    path: STANDARDS_PATH,
    title: 'Learn about our standards',
    image: menuStandardsJpg
  }
];

export default function BreederMobileHeaderDropdownLoggedOutContent() {
  return (
    <div className="BreederMobileHeaderDropdownLoggedOutContent">
      <ul className="fw-medium">
        {USER_MENU_LINKS.map(
          ({ image, path, shouldHide, title }) =>
            !shouldHide && (
              <li className="flex items-center mb2" key={path}>
                <div className="mr6">
                  <SmartImage height="64px" src={image} width="64px" />
                </div>
                <a href={path}>{title}</a>
              </li>
            )
        )}
      </ul>
    </div>
  );
}
